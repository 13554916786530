/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef, useCallback } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { FileUpload } from "primereact/fileupload";
import { Toolbar } from "primereact/toolbar";
import { InputText } from "primereact/inputtext";
import { formatLocalTime, showToast } from "utils/common";
import { Calendar } from "primereact/calendar";
import debounce from "lodash.debounce";
import { Panel } from "primereact";
import VConfirm from "components/v-confirm";
import TicketServices from "services/tickets";
import { TicketDataDoc } from "types/ticket";
import { Dropdown } from "primereact/dropdown";
import { TICKET_OPTIONS } from "utils/constant";
import { TICKET_STATUS } from "utils/enum";

const View = () => {
  const [data, setData] = useState<TicketDataDoc[]>([]);
  const [detail, setDetail] = useState<TicketDataDoc | null>(null);
  const [globalFilter, setGlobalFilter] = useState({
    status: TICKET_STATUS.NOT_CHECKED_IN,
    search: null,
    start: null,
    end: null,
  });
  const [lazyParams, setLazyParams]: any = useState({
    first: 0,
    rows: 10,
    page: 0,
    sortField: "createdAt",
    sortOrder: -1,
  });
  const [totalRecords, setTotalRecords] = useState(0);
  const [loadingExport, setLoadingExport] = useState(false);
  const toast = useRef(null);
  const dt = useRef(null);
  const refDialogDetail = useRef(null);
  const refDialogDelete = useRef(null);

  const loadData = async () => {
    try {
      const res = await TicketServices.search({
        query: {
          ...lazyParams,
          ...globalFilter,
          page: lazyParams.page + 1,
        },
      });
      if (res && res.docs) {
        setData(res.docs);
        setTotalRecords(res.totalDocs);
      } else setData([]);
    } catch (error) {
      setData([]);
    }
  };

  //TABLE EVENT
  const onPage = (event) => {
    let _lazyParams = { ...lazyParams, ...event };
    setLazyParams(_lazyParams);
  };
  const onSort = (event) => {
    let _lazyParams = { ...lazyParams, ...event };
    setLazyParams(_lazyParams);
  };
  const onFilter = (name, value) => {
    setGlobalFilter((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  const onSearch = useCallback(debounce(onFilter, 500), []);

  //CREATE OR NEW
  const openNew = () => {
    setDetail(null);
    refDialogDetail.current.show();
  };
  const editProduct = (data) => {
    setDetail({ ...data });
    refDialogDetail.current.show();
  };
  // DELETE
  const confirmDelete = (product) => {
    setDetail(product);
    refDialogDelete.current.show();
  };

  const handleDelete = async () => {
    try {
      await TicketServices.deleteTicket({
        params: {
          id: detail._id,
        },
      });
      const _data = data.filter((val) => val._id !== detail._id);
      setData(_data);
      refDialogDelete.current.close();
      setDetail(null);
      showToast(toast, "success", "Ticket Deleted");
    } catch (error) {
      showToast(toast, "error", error.errors);
    }
  };

  useEffect(() => {
    loadData();
  }, [lazyParams, globalFilter]);

  const exportData = async () => {
    try {
      setLoadingExport(true);
      const res: any = await TicketServices.exportData({
        body: {
          ...globalFilter,
          ...lazyParams,
          row: 1000,
          page: 1,
        },
      });

      import("xlsx").then((xlxs) => {
        const _data = res.docs.map((item: any, index: number) => ({
          STT: index + 1,
          Event: item?.event_name,
          "Seat Code": item?.seat_code,
          Email: item?.email,
          "Seat Number": item?.seat?.name,
          Time: item?.showtime?.startTime,
          Status: item?.status,
          Staff: item?.user_scan?.full_name,
          "Checked Time": item?.check_in_date,
        }));
        const worksheet = xlxs.utils.json_to_sheet(_data);
        const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
        const excelBuffer = xlxs.write(workbook, {
          bookType: "xlsx",
          type: "array",
        });
        saveAsExcelFile(excelBuffer, "Tickets");
      });
    } catch (error) {
    } finally {
      setLoadingExport(false);
    }
  };

  const saveAsExcelFile = (buffer, fileName) => {
    import("file-saver").then((module) => {
      if (module && module.default) {
        let EXCEL_TYPE =
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
        let EXCEL_EXTENSION = ".xlsx";
        const data = new Blob([buffer], {
          type: EXCEL_TYPE,
        });
        module.default.saveAs(
          data,
          fileName + "_export_" + new Date().getTime() + EXCEL_EXTENSION
        );
      }
    });
  };

  //RENDER
  const leftToolbarTemplate = () => {
    return (
      <React.Fragment>
        <div className="my-2">
          <Button
            label="New"
            icon="pi pi-plus"
            className="p-button-success mr-2"
            disabled={true}
            onClick={openNew}
          />
          <Button
            label="Delete"
            icon="pi pi-trash"
            className="p-button-danger"
            // onClick={confirmDeleteSelected}
            // disabled={!selectedProducts || !selectedProducts.length}
            disabled={true}
          />
        </div>
      </React.Fragment>
    );
  };
  const rightToolbarTemplate = () => {
    return (
      <React.Fragment>
        <FileUpload
          mode="basic"
          accept="image/*"
          maxFileSize={1000000}
          name="Import"
          chooseLabel="Import"
          disabled
          className="mr-2 inline-block"
        />
        <Button
          label="Export"
          icon="pi pi-upload"
          className="p-button-help"
          loading={loadingExport}
          onClick={exportData}
        />
      </React.Fragment>
    );
  };

  const actionBodyTemplate = (rowData) => {
    return (
      <div className="actions">
        <Button
          disabled
          icon="pi pi-pencil"
          className="p-button-rounded p-button-sm p-button-success mr-2"
          onClick={() => editProduct(rowData)}
        />
        <Button
          icon="pi pi-trash"
          className="p-button-rounded p-button-sm p-button-warning mr-2"
          onClick={() => confirmDelete(rowData)}
        />
      </div>
    );
  };

  const header = (
    <Panel header="Filter" toggleable collapsed={false}>
      <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
        <div className="grid my-2 align-items-center w-full">
          <span className="block p-input-icon-left col-12 md:col-3">
            <i className="ml-1 pi pi-search" />
            <InputText
              className="w-full"
              type="search"
              onInput={(e: any) => onSearch("search", e.target.value)}
              placeholder="Search..."
            />
          </span>{" "}
          <div className="col-12 md:col-3">
            <Calendar
              className="w-full"
              id="time24"
              placeholder="Start Date"
              value={globalFilter.start}
              onChange={(e: any) => onSearch("start", e.value)}
              maxDate={new Date()}
              showButtonBar
            />{" "}
          </div>
          <div className="col-12 md:col-3">
            <Calendar
              className="w-full"
              id="time24"
              placeholder="End Date"
              value={globalFilter.end}
              onChange={(e: any) => onSearch("end", e.value)}
              maxDate={new Date()}
              showButtonBar
            />
          </div>
          <div className="col-12 md:col-3">
            <Dropdown
              value={globalFilter.status}
              options={TICKET_OPTIONS}
              onChange={(e: any) => onSearch("status", e.value)}
              optionLabel="label"
              placeholder="Status"
              showClear
              className={"w-full"}
            />
          </div>
        </div>
      </div>
    </Panel>
  );

  return (
    <div className="grid View-demo">
      <div className="col-12">
        <div className="card">
          <Toast ref={toast} />
          <Toolbar
            className="mb-4"
            left={leftToolbarTemplate}
            right={rightToolbarTemplate}
          ></Toolbar>
          <DataTable
            ref={dt}
            value={data}
            header={header}
            emptyMessage="No data found."
            dataKey="id"
            size="small"
            scrollDirection="both"
            className="datatable-responsive"
            scrollable
            showGridlines
            lazy
            //panigate
            paginator
            first={lazyParams.first}
            rows={lazyParams.rows}
            totalRecords={totalRecords}
            rowsPerPageOptions={[10, 20, 50, 100]}
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            currentPageReportTemplate="{first} - {last} of {totalRecords}"
            onPage={onPage}
            //sort
            sortField={lazyParams.sortField}
            sortOrder={lazyParams.sortOrder}
            onSort={onSort}
          >
            <Column
              body={actionBodyTemplate}
              style={{ flexGrow: 1, flexBasis: "120px" }}
            ></Column>
            <Column
              field="seat_code"
              header="Seat Code"
              style={{ flexGrow: 1, flexBasis: "150px" }}
            ></Column>
            <Column
              field="email"
              header="Email"
              style={{ flexGrow: 1, flexBasis: "300px" }}
            ></Column>
            <Column
              field="seat"
              header="Seat Number"
              style={{ flexGrow: 1, flexBasis: "100px" }}
              body={(rowData) => rowData?.seat?.name}
            ></Column>
            <Column
              field="event_name"
              header="Event"
              style={{ flexGrow: 1, flexBasis: "120px" }}
            ></Column>
            <Column
              style={{ flexGrow: 1, flexBasis: "100px" }}
              field="date_time"
              header="Show Time"
              sortable
              body={(rowData) => formatLocalTime(rowData?.date?.date)}
            ></Column>

            <Column
              field="status"
              header="Status"
              style={{ flexGrow: 1, flexBasis: "120px" }}
            ></Column>
            <Column
              field="user_scan"
              header="Staff"
              style={{ flexGrow: 1, flexBasis: "200px" }}
              body={(rowData) => rowData?.user_scan?.full_name}
            ></Column>
            <Column
              field="check_in_date"
              header="Checked Time"
              style={{ flexGrow: 1, flexBasis: "120px" }}
              body={(rowData) =>
                rowData.check_in_date
                  ? formatLocalTime(rowData.check_in_date)
                  : ""
              }
            ></Column>
            <Column
              style={{ flexGrow: 1, flexBasis: "100px" }}
              field="createdAt"
              header="Created At"
              sortable
              body={(rowData) => formatLocalTime(rowData.createdAt)}
            ></Column>
          </DataTable>

          <VConfirm ref={refDialogDelete} onConfirm={handleDelete} />
        </div>
      </div>
    </div>
  );
};

const comparisonFn = function (prevProps, nextProps) {
  return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(View, comparisonFn);
