import { OrderData, OrderDataDoc } from "types/order";
import { request, parseErrorResponse } from "./request";
import qs from "query-string";

const search = (req: any): Promise<OrderData> =>
  new Promise((resolve, reject) => {
    const query = qs.stringify(req.query);
    request()
      .get(`admin/orders/search?${query}`)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const createOrder = (data: { body: any }) =>
  new Promise((rs, rj) => {
    request()
      .post("admin/orders", data.body)
      .then((res) => {
        const { data } = res.data;
        if (data) rs(data);
        else {
          rj(new Error("Something went error"));
        }
      })
      .catch(parseErrorResponse)
      .then(rj);
  });

const updateOrder = (req: { params: { id: string }; body: any }) =>
  new Promise((resolve, reject) => {
    request()
      .put("admin/orders/" + req.params.id, req.body)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const getAll = (): Promise<OrderDataDoc[]> =>
  new Promise((resolve, reject) => {
    request()
      .get(`admin/orders/`)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const deleteOrder = (req: { params: { id: string } }) =>
  new Promise((resolve, reject) => {
    request()
      .delete("admin/orders/" + req.params.id)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else {
          reject(new Error("Something went error"));
        }
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const exportData = (req: { body: any }) =>
  new Promise((resolve, reject) => {
    request()
      .post(`admin/orders/exports`, req.body)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const OrderServices = {
  search,
  createOrder,
  updateOrder,
  getAll,
  deleteOrder,
  exportData,
};

export default OrderServices;
