import { TicketData, TicketDataDoc } from "types/ticket";
import { request, parseErrorResponse } from "./request";
import qs from "query-string";

const search = (req: any): Promise<TicketData> =>
  new Promise((resolve, reject) => {
    const query = qs.stringify(req.query);
    request()
      .get(`admin/tickets/search?${query}`)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const createTicket = (data: { body: any }) =>
  new Promise((rs, rj) => {
    request()
      .post("admin/tickets", data.body)
      .then((res) => {
        const { data } = res.data;
        if (data) rs(data);
        else {
          rj(new Error("Something went error"));
        }
      })
      .catch(parseErrorResponse)
      .then(rj);
  });

const updateTicket = (req: { params: { id: string }; body: any }) =>
  new Promise((resolve, reject) => {
    request()
      .put("admin/tickets/" + req.params.id, req.body)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const getAll = (): Promise<TicketDataDoc[]> =>
  new Promise((resolve, reject) => {
    request()
      .get(`admin/tickets/`)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const deleteTicket = (req: { params: { id: string } }) =>
  new Promise((resolve, reject) => {
    request()
      .delete("admin/tickets/" + req.params.id)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else {
          reject(new Error("Something went error"));
        }
      })
      .catch(parseErrorResponse)
      .then(reject);
  });
const exportData = (req: { body: any }) =>
  new Promise((resolve, rejects) => {
    request()
      .post(`admin/tickets/exports`, req.body)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else rejects(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(rejects);
  });

const TicketServices = {
  search,
  createTicket,
  updateTicket,
  getAll,
  deleteTicket,
  exportData,
};

export default TicketServices;
